import clsx from "clsx";
import React from "react";
import "./analyticsCardRoot.scss";

interface IAnalyticsCardRootProps {
    style?: React.CSSProperties;
    className?: string;
    children: React.ReactNode;
}

export const AnalyticsCardRoot = (props: IAnalyticsCardRootProps) => {
    return <div {...props} className={clsx("analytics-card-root", props.className)} />;
};
